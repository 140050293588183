import toast from 'react-hot-toast';

const toastStyle: React.CSSProperties = {
  background: '#141414',
  color: '#fff',
  padding: '16px',
};

export const showSuccessToast = (message: string) => {
  toast.success(message, {
    position: 'top-center',
    style: toastStyle,
  });
};

export const showWarningToast = (message: string) => {
  toast(message, {
    position: 'top-center',
    icon: '⚠️',
    style: toastStyle,
  });
};

export const showErrorToast = (message: string) => {
  toast.error(message, {
    position: 'top-center',
    style: toastStyle,
  });
};
